<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<launch_nav :site="site" :preview="preview" />

				<div class="w-100 h-100 position-relative overflow-hidden d-flex">

					<div class="w-50 h-100 position-absolute top-0 start-0 p-4 d-flex align-items-start overflow-auto" style="z-index: 1">
									
						<div class="card mx-auto" style="max-width: 600px;">

							<div class="card-body p-4">

								<form @submit.prevent="submit">

									<div class="list-group mb-4">
										<label 
										v-for="( performance, performance_key ) in performances" 
										:for="'include-' + performance.key" 
										class="list-group-item d-flex align-items-start"
										:key="'performance-' + performance_key"
										>
											<span class="me-3">
												<span class="material-icons-outlined">{{ performance.icon }}</span>
											</span>
											<span class="me-4 flex-grow-1">
												<p class="fw-bold mb-0">{{ performance.name }} <span v-if="performance.score" class="badge bg-success">+{{ performance.score }}</span></p>
												<p v-if="performance.description" class="form-text mb-0">{{ performance.description }}</p>
											</span>
											<div class="form-check form-switch">
												<input 
												:name="'include-' + performance.key" 
												:value="performance.key" 
												class="form-check-input" 
												type="checkbox" 
												role="switch" 
												:id="'include-' + performance.key" 
												v-model="site.settings[performance.key]"
												>
											</div>
											
										</label>
									</div>

									<btn_submit 
										label="Update performance" 
										icon="check"  
										:icon_after="true" 
										:loading="loading" 
									/>	

								</form>

							</div>		

						</div>

					</div>

					<div class="w-50 h-100 position-absolute top-0 end-0 p-4 d-flex align-items-start overflow-auto border-start text-center" style="z-index: 1">
						
						<div class="w-100">

							<div class="row align-items-center justify-content-center">
								<div class="col position-relative" style="max-width: 600px;">

									<div class="card">

										<div class="card-body">
											<div 
											class="circle border bg-opacity-10 rounded-circle position-relative mx-auto mb-3" 
											style="width: 150px; border-width: 8px !important;"
											:class="score_class"
											>
												<div class="ratio ratio-1x1"></div>
												<span class="position-absolute top-50 start-50 translate-middle fw-bold mb-0" style="font-size: 40px;">
													{{ score }}
												</span>
											</div>
											<p class="h3 mb-2">Performance</p>
											<p class="small">Values are estimated and may vary.</p>

											<div class="d-flex align-items-center justify-content-center">
												<span class="mx-2">
													<span class="me-1" style="
														width: 12px; 
														height: 12px; 
														display: inline-block;
														border-left: calc(12px / 2) solid transparent;
														border-right: calc(12px / 2) solid transparent;
														border-bottom: 12px solid var(--bs-danger);
													"></span>
													0–49
												</span> 
												<span class="mx-2">
													<span class="me-1" style="
														width: 12px; 
														height: 12px; 
														display: inline-block;
														background: var( --bs-warning );
													"></span>
													50–89
												</span> 
												<span class="mx-2">
													<span class="me-1" style="
														width: 12px; 
														height: 12px; 
														display: inline-block;
														border-radius: 50%;
														background: var( --bs-success );
													"></span>
													90–100
												</span> 
											</div>
										</div>

									</div>

								</div>
							</div>

						</div>

					</div>				

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import launch_nav from '@/components/launch_nav'
import btn_submit from '@/components/btn_submit'
import site_service from '@/services/site_service'

export default {
	name: 'site.wordpress',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		launch_nav,
		btn_submit,
	},

	data() {
		return {
			loading: false,
			site: null,
			version: 0,
			wp_pages: [],
			performance_keys: [
				'performance-convert-to-webp',
				'performance-lazyload',
				'performance-caching',
				'performance-minify-assets',
				'performance-critical-css',
				'performance-minify-html',
			]
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		performances()
		{
			var performances = [
				{
					key: 'performance-convert-to-webp',
					icon: 'image',
					name: 'Convert to WebP',
					description: 'Uploaded images will be converted to WebP',
					score: 0	
				},
				{
					key: 'performance-lazyload',
					icon: 'refresh',
					name: 'LazyLoad',
					description: 'Images, videos and iframes will be lazy loaded',
					score: 5	
				},
				{
					key: 'performance-caching',
					icon: 'published_with_changes',
					name: 'Page caching',
					description: 'Pages and posts will be cached',
					score: 25	
				},
			]

			if ( this.site.settings['performance-caching'] ) {
				performances.push({
					key: 'performance-minify-assets',
					icon: 'compress',
					name: 'Minify CSS and JS',
					description: 'CSS and JS will be minified',
					score: 5
				});

				performances.push({
					key: 'performance-minify-html',
					icon: 'html',
					name: 'Minify HTML',
					description: 'HTML will be minified',
					score: 5
				});

				performances.push({
					key: 'performance-critical-css',
					icon: 'css',
					name: 'Include critical CSS',
					description: 'Critical CSS will be added inline',
					score: 15
				});
			}

			return performances
		},

		score()
		{
			var score = 46

			this.performances.forEach(( row ) => {
				if ( (row.key in this.site.settings) && this.site.settings[row.key] ) {
					score += row.score
				}
			})

			if ( score >= 90 ) {
				score = '90+'
			}

			return score
		},

		score_class()
		{
			var score_class = 'border-danger bg-danger text-danger'

			if ( this.score >= 90 || this.score == '90+' ) {
				score_class = 'border-success bg-success text-success'
			} else if ( this.score >= 50 ) {
				score_class = 'border-warning bg-warning text-warning'
			}

			return score_class;
		},

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},
	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.performance_keys.forEach( ( row ) => {
			if ( !(row in this.site.settings) ) {
				this.site.settings[row] = true;
			}
		});
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			this.site.question = 'performance'

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},
	}
}
</script>